import { render, staticRenderFns } from "./__Footer.vue?vue&type=template&id=3ba9beab&scoped=true&"
import script from "./__Footer.vue?vue&type=script&lang=js&"
export * from "./__Footer.vue?vue&type=script&lang=js&"
import style0 from "./__Footer.vue?vue&type=style&index=0&id=3ba9beab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba9beab",
  null
  
)

export default component.exports