<template>
    <main class="main-mobile">
        <Navbar />
        <router-view />
        <Footer />
    </main>
</template>

<script>
import Navbar from '@/components/Mobile/Layouts/__Navbar'
import Footer from '@/components/Mobile/Layouts/__Footer'

export default {
    name: 'MainMobile',
    components: {
        Navbar,
        Footer
    }
}
</script>

<style lang="scss" scoped>
section.dv-pelayanan,
section.dv-daftar,
section.dv-statistik,section.dv-profile  {
    position: relative;
    padding-top: 15vh;
    padding-left: 5vw;
    padding-right: 5vw;
}

</style>