<template>
    <footer>
        <div class="x-line">
            <span class="one"></span>
            <span class="two"></span>
        </div>
        <div class="container text-start">
            <div class="row">
                <div class="col-12 mb-1 text-center">
                    <a class="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.kn)">
                        <img src="/img/logo.png" width="60">
                    </a>
                    <p class="m-0 mt-2">Kejaksaan Negeri Barito Kuala</p>
                    <ul class="list-unstyled small text-muted">
                        <li>Currently v1.0.</li>
                        <li class="mb-2">&copy; 2022 <a href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.kn)"
                                rel="license noopener">Kejari Barito Kuala</a>. Designed and built by <a href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.noc)">NoctuaCode</a>.</li>
                    </ul>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <a href="javascript:void(0)" style="transform: scale(.8);" @click="$noctua.openNewTab($kn.link.fb)"><i class="fa-brands fa-facebook"></i> Facebook</a>
                    <a href="javascript:void(0)" style="transform: scale(.8);" @click="$noctua.openNewTab($kn.link.ig)"><i class="fa-brands fa-instagram"></i> Instagram</a>
                    <a href="javascript:void(0)" style="transform: scale(.8);" @click="$noctua.openNewTab($kn.link.wa)"><i class="fa-brands fa-whatsapp"></i> Whatsapp</a>
                    <a href="javascript:void(0)" style="transform: scale(.8);" @click="$noctua.openNewTab($kn.link.yt)"><i class="fa-brands fa-youtube"></i> Youtube</a>
                </div>
                
            </div>
        </div>
        <!-- <strong>&copy; 2022 <a href="#">Kejaksaan Negeri Barito Kuala</a> | Hak Cipta Dilindungi Undang-Undang</strong> -->
    </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
footer {
    width: 100%;
    position: relative;
    margin-top: 2rem;
    // padding-top: 2rem;
    padding-bottom: 2vh;
    background-color: white;
    background-repeat: no-repeat;
    background-position: -100px bottom;

    h5 {
        font-size: 14px;
        font-weight: bolder;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        
    }
    a {
        text-decoration: none;
        color: #2c3e50;
    }
    .x-line {
        position: relative;
        display: block;
        width: 100%;
        line-height: 0;
        margin-bottom: 2rem;
        
        .one {
            background-color: #1e6f5c;
            width: 30%;
            height: 8px;
            display: inline-block;
            margin-right: auto;
            
        }
        
        .two {
            background-color: #289672;
            width: 70%;
            height: 8px;
            display: inline-block;
            margin-left: auto;
        }
    }
}
</style>