<template>
    <div class="XNavbar">
        <nav class="x-navbar">
            <div class="container">
                <div class="instansi-logo">
                    <a class="navbar-brand" href="#">
                        <img src="/img/logo.png" width="60">
                        <!-- <span>KEJAKSAAN NEGERI BARITO KUALA</span> -->
                        <div class="title">
                            <p>Kejaksaan Republik Indonesia</p>
                            <p>Kejaksaan Negeri Barito Kuala</p>
                        </div>
                    </a>
                </div>
                <div class="tags">
                    <img src="/img/logo-ppid.png" width="90">
                    <img src="/img/tag.png" width="200">
                </div>
            </div>
        </nav>
        <div class="x-line">
            <span class="one"></span>
            <span class="two"></span>
        </div>
        <nav class="navbar navbar-expand-lg bg-light" style="border: solid 1px rgba(0, 0, 0, 0.175);">
            <div class="container">

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link" :class="$route.name === 'home' ? 'active' : ''"
                                href="javascript:void(0)" @click="$router.push({ name: 'home' })">Beranda</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#">Kegiatan</a>
                        </li> -->
                        
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" :class="$route.name.includes('profile') ? 'active' : ''"
                                href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Profile
                            </a>
                            <ul class="dropdown-menu v_re">
                                <li>
                                    <a
                                        class="dropdown-item"
                                        :class="NavActive('profile_profile-ppid')"
                                        @click="NavPush('profile_profile-ppid', $event)"
                                        href="javascript:void(0)">Profil PPID
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        :class="NavActive('profile_dasar-hukum-ppid')"
                                        @click="NavPush('profile_dasar-hukum-ppid', $event)"
                                        href="javascript:void(0)">Dasar Hukum PPID
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        :class="NavActive('profile_struktur-ppid')"
                                        @click="NavPush('profile_struktur-ppid', $event)"
                                        href="javascript:void(0)">Struktur PPID
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        :class="NavActive('profile_kontak-ppid')"
                                        @click="NavPush('profile_kontak-ppid', $event)"
                                        href="javascript:void(0)">Kontak PPID
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" :class="$route.name.includes('layanan') ? 'active' : ''" href="javascript:void(0)" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Standar Layanan
                            </a>
                            <ul class="dropdown-menu v_re">
                                <a
                                    class="dropdown-item"
                                    :class="NavActive('layanan_tata-cara-permohonan-informasi')"
                                    @click="NavPush('layanan_tata-cara-permohonan-informasi', $event)"
                                    href="javascript:void(0)">Tata Cara Permohonan Informasi
                                </a>
                                <a
                                    class="dropdown-item"
                                    :class="NavActive('layanan_tata-cara-mengajukan-keberatan')"
                                    @click="NavPush('layanan_tata-cara-mengajukan-keberatan', $event)"
                                    href="javascript:void(0)">Tata Cara Mengajukan Keberatan
                                </a>
                                <a
                                    class="dropdown-item"
                                    :class="NavActive('layanan_tata-cara-penyelesaian-sengketa-publik')"
                                    @click="NavPush('layanan_tata-cara-penyelesaian-sengketa-publik', $event)"
                                    href="javascript:void(0)">Tata Cara Penyelesaian Sengketa Publik
                                </a>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" :class="$route.name.includes('informasi_') ? 'active' : ''" href="#" role="button"
                                data-bs-toggle="dropdown" data-bs-auto-close="inside"  aria-expanded="false">
                                Daftar Informasi Publik
                            </a>
                            <ul class="dropdown-menu v_re">
                                <a
                                    class="dropdown-item"
                                    :class="NavActive('informasi_informasi-berkala')"
                                    @click="NavPush('informasi_informasi-berkala', $event)"
                                    href="javascript:void(0)">Informasi Berkala
                                </a>
                                <a
                                    class="dropdown-item"
                                    :class="NavActive('informasi_informasi-serta-merta')"
                                    @click="NavPush('informasi_informasi-serta-merta', $event)"
                                    href="javascript:void(0)">Informasi Serta Merta
                                </a>
                                <a
                                    class="dropdown-item"
                                    :class="NavActive('informasi_informasi-setiap-saat')"
                                    @click="NavPush('informasi_informasi-setiap-saat', $event)"
                                    href="javascript:void(0)">Informasi Setiap saat
                                </a>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="NavActive('statistik')"
                                @click="NavPush('statistik', $event)"
                                href="javascript:void(0)">
                                Statistik</a>
                        </li>
                    </ul>
                    <span class="navbar-text v_sosmed">
                        <i @click="$noctua.openNewTab($kn.link.fb)" class="fa-brands fa-facebook"></i>
                        <i @click="$noctua.openNewTab($kn.link.ig)" class="fa-brands fa-instagram"></i>
                        <i @click="$noctua.openNewTab($kn.link.wa)" class="fa-brands fa-whatsapp"></i>
                        <i @click="$noctua.openNewTab($kn.link.yt)" class="fa-brands fa-youtube"></i>
                    </span>
                </div>
            </div>
        </nav>

    </div>
</template>

<script>

export default {
    mounted() {
        // this.hideBar()
    },
    methods: {
        hideBar() {
            var prevScrollpos = window.pageYOffset;
            window.onscroll = function () {
                var currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    document.querySelector(".x-navbar").style.top = "0";
                    document.querySelector(".navbar.navbar-expand-lg").style.top = "0";
                    document.querySelector(".x-line").style.top = "80px";
                } else {
                    document.querySelector(".x-navbar").style.top = "-100px";
                    document.querySelector(".navbar.navbar-expand-lg").style.top = "-90px";
                    document.querySelector(".x-line").style.top = "50px";
                }
                prevScrollpos = currentScrollPos;
            }
        },
        NavActive(name){
            return this.$route.name === name ? 'active' : ''
        },
        NavPush(name, event) {
            const a = event.target.parentElement.parentElement.querySelector('a')
            const ul = event.target.parentElement
            a.classList.remove('show')
            a.setAttribute('aria-expanded', 'false')
            ul.classList.remove('show')
            ul.removeAttribute('data-bs-popper')
            return this.$router.push({ name: name })
        }
    }
}
</script>

<style lang="scss" scoped>
div.XNavbar {
    position: fixed;
    width: 100%;
    z-index: 999;


    .dropdown-menu.v_re {
        border-bottom: #1e6f5c solid 5px;
        border-radius: 12px;
        font-size: 12px;
        letter-spacing: 1px;

        a.active {
            color: #1e6f5c;
            background-color: transparent !important;
            font-weight: bold;
        }

        p.title {
            letter-spacing: 1px;
            cursor: context-menu;

            &:hover {
                background-color: transparent !important;
            }
        }
    }

    .x-line {
        position: fixed;
        display: block;
        width: 100%;
        line-height: 0;
        z-index: 999;
        top: 80px;

        .one {
            background-color: #289672;
            width: 30%;
            height: 8px;
            display: inline-block;
            margin-right: auto;

        }

        .two {
            background-color: #1e6f5c;
            width: 70%;
            height: 8px;
            display: inline-block;
            margin-left: auto;
        }
    }

    .x-line-2 {
        position: fixed;
        display: block;
        width: 100%;
        top: 80px;
        line-height: 0;

        .one {
            background-color: #289672;
            width: 20%;
            height: 8px;
            display: inline-block;
            margin-right: auto;

        }

        .two {
            background-color: #1e6f5c;
            width: 80%;
            height: 8px;
            display: inline-block;
            margin-left: auto;
        }
    }

    .navbar.navbar-expand-lg {
        transition: top 0.3s;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .nav-link.active {
        border-bottom: solid 1px #42b883;
        color: #42b883;
    }

    .nav-link:hover {
        color: #42b883;
    }

    .v_sosmed {

        i {
            cursor: pointer;
            padding-right: .5rem;
            padding-left: .5rem;
            display: inline-block;
            transform: scale(1.5);
        }

        i {
            color: #0c74d6;

            &:hover {
                color: #0c74d6;
            }
        }

        i+i {
            color: #b219ee;

            &:hover {
                color: #b219ee;
            }
        }

        i+i+i {
            color: #42b883;

            &:hover {
                color: #42b883;
            }
        }

        i+i+i+i {
            color: #f02f2f;

            &:hover {
                color: #e31830;
            }
        }
    }


    .x-navbar {
        position: relative;
        padding: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 12px;
        padding-bottom: 8px;
        background-color: white;
        // background: -webkit-gradient(linear, left top, right top, from(white), color-stop(50%, white), to(#d1d1d1)) !important;
        transition: top 0.3s;
        background-repeat: no-repeat;
        background-position: right -200px;


        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            .instansi-logo {
                display: block;

                .navbar-brand {
                    text-transform: capitalize;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    flex-direction: row;

                    p {
                        letter-spacing: 1px;
                        text-align: start;
                        margin: 0;
                        margin-left: .5rem;
                        font-size: 14px;
                    }

                    p+p {
                        font-size: 18px;
                    }
                }
            }

            .tags {
                display: block;
            }
        }

    }
}
</style>