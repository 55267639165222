<template>
    <section class="dv-home">
        <div class="container">
            <Carousel />
            <div class="data">
                <div class="card card-body">
                    <h2>0 <span>data</span></h2>
                    <h2>Informasi Berkala</h2>
                    <p>Informasi yang disediakan dan diumumkan secara <b>berkala</b></p>
                    <button class="btn btn-success">Lihat Informasi</button>
                </div>
                <div class="card card-body">
                    <h2>0 <span>data</span></h2>
                    <h2>Informasi Serta Merta</h2>
                    <p>Informasi yang disediakan dan diumumkan secara <b>serta merta</b></p>
                    <button class="btn btn-success">Lihat Informasi</button>
                </div>
                <div class="card card-body">
                    <h2>0 <span>data</span></h2>
                    <h2>Informasi Setiap Saat</h2>
                    <p>Informasi yang disediakan dan diumumkan secara <b>setiap saat</b></p>
                    <button class="btn btn-success">Lihat Informasi</button>
                </div>
            </div>

            <Maklumat />

            <Ask />

        </div>
    </section>
</template>

<script>
import Carousel from '@/components/Desktop/Beranda/__Carousel'
import Maklumat from '@/components/Mobile/Beranda/__Maklumat'
import Ask from '@/components/Mobile/Beranda/__Ask'

export default {
    components: {
        Carousel,
        Maklumat,
        Ask
    }
}
</script>

<style lang="scss" scoped>
section.dv-home {
    position: relative;
    padding: 18vh 0px 0px 0px;

    .container {
        padding: 1rem 1rem 1rem 1rem;
        .data {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .card {
                min-width: 100%;
                max-width: 100%;
                margin: .5rem;

                p b {
                    font-family: 'CorporateBold';
                    color: black;
                }

                h2 {
                    color: #289672;
                    font-size: 20vw;

                    &:nth-child(2) {
                        font-size: 8vw;
                    }

                    span {
                        color: #2c3e50;
                        font-size: 10vw;
                    }
                }
            }
        }
    }
}
</style>