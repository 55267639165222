import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Link from './db/link.json'
Vue.config.productionTip = false

Vue.prototype.$kn = {
  link: Link
}

Vue.prototype.$noctua = {
  openNewTab: (link) => {
    return window.open(link)
  }
}


new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
