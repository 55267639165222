<template>
    <footer class="mt-5">
        <div class="x-line">
            <span class="one"></span>
            <span class="two"></span>
        </div>
        <div class="container text-start">
            <div class="row">
                <div class="col-lg-11 mb-3">
                    <a class="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.kn)">
                        <img src="/img/logo.png" width="50">
                        <span class="mx-2">Kejaksaan Negeri <br> Barito Kuala</span>
                    </a>
                    <ul class="list-unstyled small text-muted">
                        <li class="mb-2">&copy; 2022 <a href="javascript:void(0)"
                                rel="license noopener" @click="$noctua.openNewTab($kn.link.kn)">Kejari Barito Kuala</a>.</li>
                        <li class="mb-2">Designed and built by <a
                                href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.noc)">NoctuaCode</a>.</li>
                        <li class="mb-2">Currently v1.0.</li>
                    </ul>
                </div>
                <div class="col-lg-1 mb-3">
                    <ul class="list-unstyled">
                        <li class="mb-2"><a href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.fb)"><i class="fa-brands fa-facebook"></i> Facebook</a></li>
                        <li class="mb-2"><a href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.ig)"><i class="fa-brands fa-instagram"></i> Instagram</a></li>
                        <li class="mb-2"><a href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.wa)"><i class="fa-brands fa-whatsapp"></i> Whatsapp</a></li>
                        <li class="mb-2"><a href="javascript:void(0)" @click="$noctua.openNewTab($kn.link.yt)"><i class="fa-brands fa-youtube"></i> Youtube</a></li>
                    </ul>
                </div>
                
            </div>
        </div>
    </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
footer {
    width: 100%;
    position: relative;
    margin-top: 2rem;
    // padding-top: 2rem;
    padding-bottom: 2vh;
    background-color: white;
    background-repeat: no-repeat;
    background-position: -100px -100px;

    h5 {
        font-size: 14px;
        font-weight: bolder;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        
    }
    a {
        text-decoration: none;
        color: #2c3e50;
    }
    .x-line {
        position: relative;
        display: block;
        width: 100%;
        line-height: 0;
        margin-bottom: 2rem;
        
        .one {
            background-color: #1e6f5c;
            width: 30%;
            height: 8px;
            display: inline-block;
            margin-right: auto;
            
        }
        
        .two {
            background-color: #289672;
            width: 70%;
            height: 8px;
            display: inline-block;
            margin-left: auto;
        }
    }
}
</style>