<template>
  <div id="app">

    <template v-if="mobile">
      <MainMobile />
    </template>

    <template v-else>
  
      <MainDesktop />

    </template>

  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import MainDesktop from '@/views/Desktop/MainDesktop'
import MainMobile from '@/views/Mobile/MainMobile'

export default {
  data(){
    return {
      mobile: false
    }
  },
  components: {
    MainDesktop,
    MainMobile
  },
  created(){
    this.mobile = isMobile
  }
}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'CorporateRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
