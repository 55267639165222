import { render, staticRenderFns } from "./__Profile.vue?vue&type=template&id=179609e7&scoped=true&"
var script = {}
import style0 from "./__Profile.vue?vue&type=style&index=0&id=179609e7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179609e7",
  null
  
)

export default component.exports