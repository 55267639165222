<template>
    <main class="main-desktop">
        <Navbar />
        <router-view />
        <Footer />
    </main>
</template>

<script>
import Navbar from '@/components/Desktop/Layouts/__Navbar'
import Footer from '@/components/Desktop/Layouts/__Footer'

export default {
    name: 'MainDesktop',
    components: {
        Navbar,
        Footer
    }
}
</script>

<style lang="scss" scoped>
section.dv-pelayanan,
section.dv-daftar,
section.dv-statistik,
section.dv-profile {
    position: relative;
    padding-top: 18vh;
}
</style>