<template>
    <section class="dv-statistik container text-start">
        <div class="title">
            <h2>Statistik Pelayanan Informasi Publik</h2>
            <span class="line"></span>
        </div>
        <h5 class="text-center">Jumlah Data Informasi Publik</h5>
        <div class="data">
            <div class="card card-body">
                <h2>0 <span>data</span></h2>
                <h2>Informasi Berkala</h2>
                <p>Informasi yang disediakan dan diumumkan secara <b>berkala</b></p>
                <button class="btn btn-success">Lihat Informasi</button>
            </div>
            <div class="card card-body">
                <h2>0 <span>data</span></h2>
                <h2>Informasi Serta Merta</h2>
                <p>Informasi yang disediakan dan diumumkan secara <b>serta merta</b></p>
                <button class="btn btn-success">Lihat Informasi</button>
            </div>
            <div class="card card-body">
                <h2>0 <span>data</span></h2>
                <h2>Informasi Setiap Saat</h2>
                <p>Informasi yang disediakan dan diumumkan secara <b>setiap saat</b></p>
                <button class="btn btn-success">Lihat Informasi</button>
            </div>
        </div>
        <h5 class="text-center">Statistik Akses informasi publik</h5>
        <hr class="my-4">
        <div class="cjs-1">
            <h5 class="text-center mb-3">Statistik Akses terhadap Informasi Publik</h5>
            <canvas id="cjs-akses-publik" width="400" 
                style="background-color:rgba(255, 255, 255, 0);border-radius:0px;width:700px;height:400px;padding-left:0px;padding-right:0px;padding-top:0px;padding-bottom:0px"></canvas>
            <hr class="my-4">
            <h5 class="text-center mb-3">Statistik Permohonan Informasi Publik</h5>
            <canvas id="cjs-permohonan-publik" width="400" 
                style="background-color:rgba(255, 255, 255, 0);border-radius:0px;width:100px;height:400px;padding-left:0px;padding-right:0px;padding-top:0px;padding-bottom:0px"></canvas>
        </div>
        <div class="cjs-2 row mt-5">
            <div class="col-12 mb-5" style="height: 40vh;">
                <h5 class="mb-2">Statistik Cara Memperoleh Informasi</h5>
                <canvas id="cjs-memperoleh-info"
                    style="background-color:rgba(255, 255, 255, 0);border-radius:0px;height:100%;width:100%;padding-left:0px;padding-right:0px;padding-top:0px;padding-bottom:0px"></canvas>
            </div>
            <div class="col-12 mb-5">
                <h5 class="mb-2">Statistik Cara Mendapatkan Informasi</h5>
                <canvas id="cjs-mendapatkan-info"
                    style="background-color:rgba(255, 255, 255, 0);border-radius:0px;height:100%;width:100%;padding-left:0px;padding-right:0px;padding-top:0px;padding-bottom:0px"></canvas>
            </div>
            <div class="col-12 mb-5">
                <h5 class="mb-2">Statistik Bentuk Informasi yang Dikuasai</h5>
                <canvas id="cjs-dikuasai-info"
                    style="background-color:rgba(255, 255, 255, 0);border-radius:0px;height:100%;width:100%;padding-left:0px;padding-right:0px;padding-top:0px;padding-bottom:0px"></canvas>
            </div>
        </div>
    </section>
</template>

<script>
import chartJs from 'chart.js/auto'
import dbCJS1 from '@/db/cjs-1.json'
import dbCJS2 from '@/db/cjs-2.json'

export default {
    data() {
        return {
            chart: {
                akses_publik: null,
                permohonan_publik: null,
                memperoleh_info: null,
                mendapatkan_info: null,
                dikuasai_info: null
            }
        }
    },
    mounted() {
        this.InitializingChart()
    },
    methods: {
        InitializingChart(){
            const dataCJS1 = dbCJS1.data
            const dataCJS2 = dbCJS2.data

            let parseCJS1 = dataCJS1.akses.diterima
            let parseValueCJS1 = []
            Object.keys(parseCJS1).forEach(x => {
                parseValueCJS1.push(parseCJS1[x])
            });

            let parseCJS2 = dataCJS1.permohonan
            let parseValueCJS2 = []
            Object.keys(parseCJS2.diterima).forEach(x => {
                parseValueCJS2.push(parseCJS2.diterima[x])
            });
            Object.keys(parseCJS2.ditolak).forEach(x => {
                parseValueCJS2.push(parseCJS2.ditolak[x])
            });
            
            const optionsCJS1 = {
                responsive: true,
                scales: {
                    yAxis: {
                        max: Math.max(...parseValueCJS1) + 2,
                        min: 0
                    }
                }
            }

            const optionsCJS2 = {
                responsive: true,
                scales: {
                    yAxis: {
                        max: Math.max(...parseValueCJS2) + 2,
                        min: 0
                    }
                }
            }

            this.chart.akses_publik = new chartJs(document.getElementById('cjs-akses-publik'), {
                type: 'bar',
                data: {
                    labels: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
                    datasets: [{
                        label: 'Diterima',
                        backgroundColor: 'rgb(40, 150, 114)',
                        borderColor: 'rgb(40, 150, 114)',
                        borderWidth: 1,
                        data: [
                            dataCJS1.akses.diterima.januari,
                            dataCJS1.akses.diterima.februari,
                            dataCJS1.akses.diterima.maret,
                            dataCJS1.akses.diterima.april,
                            dataCJS1.akses.diterima.mei,
                            dataCJS1.akses.diterima.juni,
                            dataCJS1.akses.diterima.juli,
                            dataCJS1.akses.diterima.agustus,
                            dataCJS1.akses.diterima.september,
                            dataCJS1.akses.diterima.oktober,
                            dataCJS1.akses.diterima.november,
                            dataCJS1.akses.diterima.desember,
                        ]
                    }]
                },
                options: optionsCJS1
            })
            this.chart.permohonan_publik = new chartJs(document.getElementById('cjs-permohonan-publik'), {
                type: 'bar',
                data: {
                    labels: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
                    datasets: [
                        {
                            label: 'Diterima',
                            backgroundColor: 'rgb(40, 150, 114)',
                            borderColor: 'rgb(40, 150, 114)',
                            borderWidth: 1,
                            data: [
                                dataCJS1.permohonan.diterima.januari,
                                dataCJS1.permohonan.diterima.februari,
                                dataCJS1.permohonan.diterima.maret,
                                dataCJS1.permohonan.diterima.april,
                                dataCJS1.permohonan.diterima.mei,
                                dataCJS1.permohonan.diterima.juni,
                                dataCJS1.permohonan.diterima.juli,
                                dataCJS1.permohonan.diterima.agustus,
                                dataCJS1.permohonan.diterima.september,
                                dataCJS1.permohonan.diterima.oktober,
                                dataCJS1.permohonan.diterima.november,
                                dataCJS1.permohonan.diterima.desember,
                            ],
                        },
                        {
                            label: 'Ditolak',
                            backgroundColor: 'rgb(255,0,0)',
                            borderColor: 'rgb(255,0,0)',
                            borderWidth: 1,
                            data: [
                                dataCJS1.permohonan.ditolak.januari,
                                dataCJS1.permohonan.ditolak.februari,
                                dataCJS1.permohonan.ditolak.maret,
                                dataCJS1.permohonan.ditolak.april,
                                dataCJS1.permohonan.ditolak.mei,
                                dataCJS1.permohonan.ditolak.juni,
                                dataCJS1.permohonan.ditolak.juli,
                                dataCJS1.permohonan.ditolak.agustus,
                                dataCJS1.permohonan.ditolak.september,
                                dataCJS1.permohonan.ditolak.oktober,
                                dataCJS1.permohonan.ditolak.november,
                                dataCJS1.permohonan.ditolak.desember,
                            ],
                        }
                    ]
                },
                options: optionsCJS2
            })

            
            this.chart.memperoleh_info = new chartJs(document.getElementById('cjs-memperoleh-info'), {
                type: 'bar',
                data: {
                    labels: [""],
                    datasets: [
                        {
                            label: 'Mendengarkan / melihat',
                            backgroundColor: 'rgb(40, 150, 114)',
                            borderColor: 'rgb(40, 150, 114)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.memperoleh.mendengarkan
                            ],
                        },
                        {
                            label: 'Mendapatkan salinan',
                            backgroundColor: 'rgb(230, 50, 50)',
                            borderColor: 'rgb(230, 50, 50)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.memperoleh.mendapatkan
                            ],
                        }
                    ]
                },
                options: optionsCJS1
            })
            this.chart.mendapatkan_info = new chartJs(document.getElementById('cjs-mendapatkan-info'), {
                type: 'bar',
                data: {
                    labels: [""],
                    datasets: [
                        {
                            label: 'Mengambil langsung',
                            backgroundColor: 'rgb(255, 138, 36)',
                            borderColor: 'rgb(255, 138, 36)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.mendapatkan.mengambil
                            ],
                        },
                        {
                            label: 'Kurir',
                            backgroundColor: 'rgb(230, 50, 50)',
                            borderColor: 'rgb(230, 50, 50)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.mendapatkan.kurir
                            ],
                        },
                        {
                            label: 'Pos',
                            backgroundColor: 'rgb(36, 160, 255)',
                            borderColor: 'rgb(36, 160, 255)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.mendapatkan.pos
                            ],
                        },
                        {
                            label: 'Faksimili',
                            backgroundColor: 'rgb(40, 150, 114)',
                            borderColor: 'rgb(40, 150, 114)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.mendapatkan.faksimil
                            ],
                        },
                        {
                            label: 'Email',
                            backgroundColor: 'rgb(149, 36, 255)',
                            borderColor: 'rgb(149, 36, 255)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.mendapatkan.email
                            ],
                        }
                    ]
                },
                options: optionsCJS1
            })
            this.chart.dikuasai_info = new chartJs(document.getElementById('cjs-dikuasai-info'), {
                type: 'bar',
                data: {
                    labels: [""],
                    datasets: [
                        {
                            label: 'Softcopy',
                            backgroundColor: 'rgb(40, 150, 114)',
                            borderColor: 'rgb(40, 150, 114)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.bentuk.softcopy
                            ],
                        },
                        {
                            label: 'Hardcopy',
                            backgroundColor: 'rgb(230, 50, 50)',
                            borderColor: 'rgb(230, 50, 50)',
                            borderWidth: 1,
                            data: [
                                dataCJS2.bentuk.hardcopy
                            ],
                        }
                    ]
                },
                options: optionsCJS1
            })
        }
    }
}
</script>

<style lang="scss" scoped>
div.title {
    text-align: start;
    font-size: 2vw;
    margin-bottom: 2rem;

    span {
        display: block;
        background-color: #289672;
        width: 100px;
        height: 2px;
    }
}

canvas {
    margin: auto;
}


.cjs-1 {
    canvas {
        width: 80% !important;
        height: 80vh;
    }
}

.cjs-2 {
    .col-12 {
        min-height: 40vh;
        text-align: center;
    }
}


.data {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    .card {
        min-width: 100%;
        max-width: 100%;
        margin: .5rem;

        p b {
            font-family: 'CorporateBold';
            color: black;
        }

        h2 {
            color: #289672;
            font-size: 14vw;

            &:nth-child(2) {
                font-size: 7vw;
            }

            span {
                color: #2c3e50;
                font-size: 6vw;
            }
        }
    }
}
</style>