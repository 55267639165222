<template>
    <div class="dc-carousel">
        <carousel :autoplay="true" :perPage="1" :speed="100" :loop="true">
            <slide>
                <div class="img-wrapper">
                    <img src="/img/banner1.png" class="card-img-top">
                </div>
            </slide>
            <slide>
                <div class="img-wrapper">
                    <img src="/img/banner2.png" class="card-img-top">
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    components: {
        Carousel,
        Slide
    }
}
</script>