import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from 'mobile-device-detect'

import Desk_Home from '@/views/Desktop/__Home'
import Mobile_Home from '@/views/Mobile/__Home'

import Desk_Profile_Profile from '@/views/Desktop/Profile/__Profile'
import Desk_Profile_DasarHukumPPID from '@/views/Desktop/Profile/__DasarHukum'
import Desk_Profile_StrukturPPID from '@/views/Desktop/Profile/__Struktur'
import Desk_Profile_Kontak from '@/views/Desktop/Profile/__Kontak'

import Desk_Pelayanan_Permohonan from '@/views/Desktop/Layanan/__Permohonan'
import Desk_Pelayanan_Keberatan from '@/views/Desktop/Layanan/__Keberatan'
import Desk_Pelayanan_Sengketa from '@/views/Desktop/Layanan/__Sengketa'

import Desk_Informasi_Berkala from '@/views/Desktop/Informasi/__Berkala'
import Desk_Informasi_Merta from '@/views/Desktop/Informasi/__Merta'
import Desk_Informasi_Saat from '@/views/Desktop/Informasi/__Saat'

import Desk_Statistik from '@/views/Desktop/__Statistik'
import Mobile_Statistik from '@/views/Mobile/__Statistik'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Desk_Home
  },
  {
    path: '/m',
    name: 'm-home',
    component: Mobile_Home
  },
  {
    path: '/m/statistik',
    name: 'm-statistik',
    component: Mobile_Statistik
  },
  {
    path: '/profile/profile-ppid',
    name: 'profile_profile-ppid',
    component: Desk_Profile_Profile
  },
  {
    path: '/profile/dasar-hukum-ppid',
    name: 'profile_dasar-hukum-ppid',
    component: Desk_Profile_DasarHukumPPID
  },
  {
    path: '/profile/struktur-ppid',
    name: 'profile_struktur-ppid',
    component: Desk_Profile_StrukturPPID
  },
  {
    path: '/profile/kontak-ppid',
    name: 'profile_kontak-ppid',
    component: Desk_Profile_Kontak
  },
  {
    path: '/standar-layanan/tata-cara-permohonan-informasi',
    name: 'layanan_tata-cara-permohonan-informasi',
    component: Desk_Pelayanan_Permohonan
  },
  {
    path: '/standar-layanan/tata-cara-mengajukan-keberatan',
    name: 'layanan_tata-cara-mengajukan-keberatan',
    component: Desk_Pelayanan_Keberatan
  },
  {
    path: '/standar-layanan/tata-cara-penyelesaian-sengketa-publik',
    name: 'layanan_tata-cara-penyelesaian-sengketa-publik',
    component: Desk_Pelayanan_Sengketa
  },
  {
    path: '/daftar-informasi-publik/informasi-berkala',
    name: 'informasi_informasi-berkala',
    component: Desk_Informasi_Berkala
  },
  {
    path: '/daftar-informasi-publik/informasi-serta-merta',
    name: 'informasi_informasi-serta-merta',
    component: Desk_Informasi_Merta
  },
  {
    path: '/daftar-informasi-publik/informasi-setiap-saat',
    name: 'informasi_informasi-setiap-saat',
    component: Desk_Informasi_Saat
  },
  {
    path: '/statistik',
    name: 'statistik',
    component: Desk_Statistik
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(isMobile && to.name === 'home'){
    return next({ name: 'm-home' })
  } else if (isMobile && to.name === 'statistik') {
    return next({ name: 'm-statistik' })
  } else {
    if (!isMobile && to.name === 'm-home') {
      return next({ name: 'home' })
    } else if (!isMobile && to.name === 'm-statistik') {
      return next({ name: 'statistik' })
    } else {
      return next()
    }
  }
})

export default router
