<template>
    <div class="XNavbar">
        <nav class="x-navbar">
            <div class="container">
                <div class="instansi-logo">
                    <a class="navbar-brand" href="#">
                        <img src="/img/logo.png" width="60">
                        <!-- <span>KEJAKSAAN NEGERI BARITO KUALA</span> -->
                        <div class="title">
                            <p>Kejaksaan Republik Indonesia</p>
                            <p>Kejaksaan Negeri Barito Kuala</p>
                        </div>
                    </a>
                </div>
                <div class="tags">
                    <img src="/img/logo-ppid.png" width="90">
                    <img src="/img/tag.png" width="200">
                </div>
            </div>
        </nav>
        <div class="x-line">
            <span class="one"></span>
            <span class="two"></span>
        </div>
        <nav class="cs-navbar bg-light" style="border: solid 1px rgba(0, 0, 0, 0.175);">
            <div class="link-nav">
                <a
                    :class="$route.name === 'm-home' ? 'active' : ''"
                    @click="$router.push({ name: 'm-home' })"
                    href="javascript:void(0)"
                    >Beranda
                </a>
                <a
                    :class="$route.name === 'm-statistik' ? 'active' : ''"
                    @click="$router.push({ name: 'm-statistik' })"
                    href="javascript:void(0)"
                    >Statistik
                </a>
            </div>
            <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" >
                <i class="fa-solid fa-bars"></i>
            </a>
          
        </nav>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <img class="kn" src="/img/logo.png">
                <img class="ppid" src="/img/logo-ppid.png">
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body text-start">
                <div class="x-line">
                    <span class="one"></span>
                    <span class="two"></span>
                </div>
                <img class="b-overlay" src="/img/tag.png">
                
                <a class="btn btn-success d-block mb-2 d-flex justify-content-between align-items-center" href="javascript:void(0)" role="button">
                    <p class="m-0">Profile</p><i class="fa-solid fa-plus"></i>
                </a>
                <div class="card card-body">
                    <a :class="NavActive('profile_profile-ppid')" @click="NavPush('profile_profile-ppid', $event)"
                        href="javascript:void(0)"><i class="fa-solid fa-caret-right"></i> Profil PPID
                    </a>
                    <a :class="NavActive('profile_dasar-hukum-ppid')"
                        @click="NavPush('profile_dasar-hukum-ppid', $event)" href="javascript:void(0)"><i class="fa-solid fa-caret-right"></i> Dasar Hukum PPID
                    </a>
                    <a :class="NavActive('profile_struktur-ppid')" @click="NavPush('profile_struktur-ppid', $event)"
                        href="javascript:void(0)"><i class="fa-solid fa-caret-right"></i> Struktur PPID
                    </a>
                    <a :class="NavActive('profile_kontak-ppid')" @click="NavPush('profile_kontak-ppid', $event)"
                        href="javascript:void(0)"><i class="fa-solid fa-caret-right"></i> Kontak PPID
                    </a>
                </div>
                <a class="btn btn-success d-block mb-2 d-flex justify-content-between align-items-center"
                    href="javascript:void(0)" role="button">
                    <p class="m-0">Standar Pelayanan</p><i class="fa-solid fa-plus"></i>
                </a>
                <div class="card card-body">
                    <a :class="NavActive('layanan_tata-cara-permohonan-informasi')"
                        @click="NavPush('layanan_tata-cara-permohonan-informasi', $event)" href="javascript:void(0)">Tata Cara Permohonan
                        Informasi
                    </a>
                    <a :class="NavActive('layanan_tata-cara-mengajukan-keberatan')"
                        @click="NavPush('layanan_tata-cara-mengajukan-keberatan', $event)" href="javascript:void(0)">Tata Cara Mengajukan
                        Keberatan
                    </a>
                    <a :class="NavActive('layanan_tata-cara-penyelesaian-sengketa-publik')"
                        @click="NavPush('layanan_tata-cara-penyelesaian-sengketa-publik', $event)" href="javascript:void(0)">Tata Cara
                        Penyelesaian Sengketa Publik
                    </a>
                </div>
                <a class="btn btn-success d-block mb-2 d-flex justify-content-between align-items-center"
                    href="javascript:void(0)" role="button">
                    <p class="m-0">Daftar Infromasi Publik</p><i class="fa-solid fa-plus"></i>
                </a>
                <div class="card card-body">
                    <a :class="NavActive('informasi_informasi-berkala')"
                        @click="NavPush('informasi_informasi-berkala', $event)" href="javascript:void(0)">Informasi Berkala
                    </a>
                    <a :class="NavActive('informasi_informasi-serta-merta')"
                        @click="NavPush('informasi_informasi-serta-merta', $event)" href="javascript:void(0)">Informasi Serta Merta
                    </a>
                    <a :class="NavActive('informasi_informasi-setiap-saat')"
                        @click="NavPush('informasi_informasi-setiap-saat', $event)" href="javascript:void(0)">Informasi Setiap saat
                    </a>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
import bsOffcanvas from 'bootstrap/js/dist/offcanvas'

export default {
    data(){
        return {
            offcanvas: null
        }
    },
    mounted() {
        this.offcanvas = new bsOffcanvas('#offcanvasExample')
    },
    methods: {
        hideBar() {
            var prevScrollpos = window.pageYOffset;
            window.onscroll = function () {
                var currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    document.querySelector(".x-navbar").style.top = "0";
                    document.querySelector(".navbar.navbar-expand-lg").style.top = "0";
                    document.querySelector(".x-line").style.top = "80px";
                } else {
                    document.querySelector(".x-navbar").style.top = "-100px";
                    document.querySelector(".navbar.navbar-expand-lg").style.top = "-90px";
                    document.querySelector(".x-line").style.top = "50px";
                }
                prevScrollpos = currentScrollPos;
            }
        },
        NavActive(name){
            return this.$route.name === name ? 'active' : ''
        },
        NavPush(name) {
            document.querySelector('.offcanvas-backdrop').remove()
            this.offcanvas.hide()
            return this.$router.push({ name: name })
        },
        hideOffcanvas(){

        }
    }
}
</script>

<style lang="scss" scoped>
div.XNavbar {
    position: fixed;
    width: 100%;
    z-index: 999;

    .offcanvas {
        .offcanvas-header {
            justify-content: start;
            background-repeat: no-repeat;
            background-position: right -270px;
            img.kn {
                width: 15vw;
                margin-right: 10px;
            }
            img.ppid {
                width: 28vw;
            }

            button {
                position: absolute;
                right: 20px;
                top: 20px;
            }
            
        }
        .offcanvas-body {
            overflow: hidden;
            padding-top: 0;
            img.b-overlay {
                position: absolute;
                bottom: 10px;
                left: 10px;
                width: 50vw;
            }
            .x-line {
                position: relative !important;
                display: block !important;
                top: 0 !important;
                width: 120%;
                margin-bottom: 1rem;
                margin-left: -2rem;
                margin-right: -2rem;
            }
        }
        .card.card-body {
            
            a {
                text-decoration: none;
                margin-top: 5px;
                margin-bottom: 5px;
                color: #2c3e50;

                i {
                    display: none;
                    margin-right: 5px;
                }

                &.active {
                    color: #1e6f5c;
                    background-color: transparent !important;
                    font-weight: bold;

                    i {
                        display: inline;
                    }
                } 
            }
        }
    }

    .dropdown-menu.v_re {
        border-bottom: #1e6f5c solid 5px;
        border-radius: 12px;
        font-size: 12px;
        letter-spacing: 1px;

        a.active {
            color: #1e6f5c;
            background-color: transparent !important;
            font-weight: bold;
        }

        p.title {
            letter-spacing: 1px;
            cursor: context-menu;

            &:hover {
                background-color: transparent !important;
            }
        }
    }

    nav.cs-navbar {
        border-bottom: 1px solid black;
        height: 12vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1rem 1rem 1rem;
        font-size: 4vw;

        .link-nav {
            display: flex;
            justify-content: start;
            align-items: center;
        }

        .dropdown-toggle.menu::after {
            display: none;
        }

        a {
            text-decoration: none;
            margin-right: 10px;
            color: #2c3e50;

            &.active {
                border-bottom: solid 1px #42b883;
                color: #42b883;
            }
        }
    }

    .x-line {
        position: fixed;
        display: block;
        width: 100%;
        line-height: 0;
        z-index: 999;
        top: 15vw;

        .one {
            background-color: #289672;
            width: 30%;
            height: 5px;
            display: inline-block;
            margin-right: auto;

        }

        .two {
            background-color: #1e6f5c;
            width: 70%;
            height: 5px;
            display: inline-block;
            margin-left: auto;
        }
    }

    .x-line-2 {
        position: fixed;
        display: block;
        width: 100%;
        top: 80px;
        line-height: 0;

        .one {
            background-color: #289672;
            width: 20%;
            height: 8px;
            display: inline-block;
            margin-right: auto;

        }

        .two {
            background-color: #1e6f5c;
            width: 80%;
            height: 8px;
            display: inline-block;
            margin-left: auto;
        }
    }

    .navbar.navbar-expand-lg {
        transition: top 0.3s;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .nav-link.active {
        border-bottom: solid 1px #42b883;
        color: #42b883;
    }

    .nav-link:hover {
        color: #42b883;
    }

    .v_sosmed {

        i {
            cursor: pointer;
            padding-right: .5rem;
            padding-left: .5rem;
            display: inline-block;
            transform: scale(1.5);
        }

        i {
            color: #0c74d6;

            &:hover {
                color: #0c74d6;
            }
        }

        i+i {
            color: #b219ee;

            &:hover {
                color: #b219ee;
            }
        }

        i+i+i {
            color: #42b883;

            &:hover {
                color: #42b883;
            }
        }
    }


    .x-navbar {
        position: relative;
        padding: 0;
        padding-left: 1vw;
        padding-right: 1vw;
        padding-top: 12px;
        padding-bottom: 8px;
        background-color: white;
        // background: -webkit-gradient(linear, left top, right top, from(white), color-stop(50%, white), to(#d1d1d1)) !important;
        transition: top 0.3s;
        background-repeat: no-repeat;
        background-position: right -200px;


        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            .instansi-logo {
                display: block;

                .navbar-brand {
                    text-transform: capitalize;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    flex-direction: row;
                    img {
                        width: 10vw;
                    }

                    p {
                        letter-spacing: 1px;
                        text-align: start;
                        margin: 0;
                        margin-left: .5rem;
                        font-size: 2vw;
                    }

                    p+p {
                        font-size: 2.5vw;
                    }
                }
            }

            .tags {
                display: block;
                img {
                    width: 15vw;
                }
            }
        }

    }
}
</style>